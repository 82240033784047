.awsui_root_11huc_1mnuo_221_apfp:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
  hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  color: #16191f;
  font-weight: 400;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.awsui_word-wrap_11huc_1mnuo_251_apfp:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_progress-container_11huc_1mnuo_273_apfp:not(#\9) {
  display: flex;
  align-items: center;
  max-width: 800px;
  height: 22px;
}

.awsui_progress-container_11huc_1mnuo_273_apfp:not(#\9) {
  max-width: 100%;
}

.awsui_root_xttbq_1i4dh_93_apfp:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.awsui_root_3bgfn_im8v7_5_apfp:not(#\9) {
}

progress {
  appearance: auto;
  box-sizing: border-box;
  display: inline-block;
  block-size: 1em;
  inline-size: 10em;
  vertical-align: -0.2em;
  writing-mode: horizontal-tb !important;
}

progress {
  vertical-align: baseline;
}

.awsui_progress_11huc_1mnuo_185_apfp:not(#\9) {
  width: 100%;
  margin-right: 12px;
  min-width: 0;
  height: 4px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 0 none;
  background-color: #eaeded;
}

progress.awsui_progress_11huc_1mnuo_185_apfp:not(#\9) {
  -webkit-appearance: none;
  appearance: none;
}

progress.awsui_progress_11huc_1mnuo_185_apfp:not(#\9)::-webkit-progress-bar {
  background-color: #eaeded;
  border-radius: 10px;
}

progress.awsui_progress_11huc_1mnuo_185_apfp:not(#\9)::-webkit-progress-value {
  background-color: #0073bb;
  border-radius: 10px;
}

progress::-moz-progress-bar {
  border-radius: 10px;
}

.awsui_percentage-container_11huc_1mnuo_280_apfp:not(#\9) {
  width: 33px;
  flex-shrink: 0;
  white-space: nowrap;
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
}

.awsui_percentage-container_11huc_1mnuo_280_apfp:not(#\9) {
  visibility: hidden;
}

small {
  font-size: smaller;
}

small {
  font-size: 80%;
}

.awsui_root_18wu0_15enz_93_apfp:not(#\9) {
  font-size: 14px;
  line-height: 22px;
  color: #16191f;
  font-weight: 400;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.awsui_percentage_11huc_1mnuo_280_apfp:not(#\9) {
}

.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp:not(
    #\9
  ) {
  display: inline-block;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp.awsui_color-default_18wu0_15enz_207_apfp:not(
    #\9
  ) {
  color: #687078;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp.awsui_font-size-default_18wu0_15enz_223_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_code-variant_18wu0_15enz_207.awsui_font-size-default_18wu0_15enz_223_apfp:not(
    #\9
  ) {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_p-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_code-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_pre-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_samp-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_a-variant_18wu0_15enz_219.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ) {
  font-weight: 400;
}

small {
  font-size: smaller;
}

small {
  font-size: 80%;
}

.awsui_root_18wu0_15enz_93_apfp:not(#\9) {
  font-size: 14px;
  line-height: 22px;
  color: #16191f;
  font-weight: 400;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.awsui_word-wrap_11huc_1mnuo_251_apfp:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp:not(
    #\9
  ) {
  display: inline-block;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_d-block_18wu0_15enz_989_apfp:not(#\9) {
  display: block;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp.awsui_color-default_18wu0_15enz_207_apfp:not(
    #\9
  ) {
  color: #687078;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp.awsui_font-size-default_18wu0_15enz_223_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_code-variant_18wu0_15enz_207.awsui_font-size-default_18wu0_15enz_223_apfp:not(
    #\9
  ) {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
}

.awsui_box_18wu0_15enz_207_apfp.awsui_p-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_code-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_pre-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_samp-variant_18wu0_15enz_207.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_small-variant_18wu0_15enz_215_apfp.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ),
.awsui_box_18wu0_15enz_207_apfp.awsui_a-variant_18wu0_15enz_219.awsui_font-weight-default_18wu0_15enz_263_apfp:not(
    #\9
  ) {
  font-weight: 400;
}
