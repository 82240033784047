.step-details-file-upload {
  height: 0px;
  width: 0px;
}

.step-details-file-upload-button {
  margin-top: 0.8em;
}

.step-details-file-upload input {
  visibility: hidden !important;
}

.step-details-file-upload-status {
  margin: auto 1.5rem auto 0;
}

.step-details-file-upload-metadata:first-child {
  padding: 0;
}

.step-details-file-upload-delete {
  color: #545b64 !important;
  cursor: pointer;
  margin: 0.3rem;
}

.step-details-file-upload-container {
  margin-left: 0.1em !important;
  border: 1px solid #00a1c9;
  background: #f1faff;
  display: flex;
  justify-content: space-between;
}

.step-details-file-button {
  padding: 0;
}

.step-details-file-button-container {
  display: flex;
}

.step-details-file-download-icon {
  margin-left: 0.5rem;
}
