.dashboard-summary {
  width: 50%;
}

.file-upload-container {
  padding: 0.4em 0.4em 0em 0.4em;
  margin-left: 0.1em !important;
  border: 1px solid #00a1c9;
  background: #f1faff;
  display: flex;
}

.table-info {
  font-weight: bold;
  font-style: italic;
  display: block;
  float: right;
}
