.chat-history {
  height: 250px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.chat-bubble {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.user-bubble {
  align-self: flex-end;
  background-color: #0072d3;
  color: white;
}

.bot-bubble {
  align-self: flex-start;
  background-color: #e9ebed;
  color: black;
}

.animated-progress-bar {
  position: relative;
  height: 3px; /* Adjust this value to change the height of the progress bar */
  overflow: hidden;
}

.animated-progress-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(
    to right,
    #99f7ff 0,
    #0096fa 10%,
    #bf80ff 24%,
    #7300e5 50%,
    #bf80ff 76%,
    #0096fa 90%,
    #99f7ff 100%
  );
  animation: progress-animation 2s infinite linear;
}

.loading-progress-bar .awsui-polaris-progress-bar__fill {
  visibility: hidden;
}

@keyframes progress-animation {
  0% {
    width: 0;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}
