.review-status-text {
  display: inline-block;
  font-size: 1.2em;
}

.review-status-text-process {
  color: black;
}

.review-status-text-complete {
  color: green;
}

.review-status {
  display: flex;
  justify-content: space-evenly;
}

#statusbar__approved-status {
  display: flex;
  justify-content: space-evenly;
}
